<template>
  <f7-page class="login-page">
    <f7-navbar back-link="Back"> </f7-navbar>

    <meta name="robots" content="noindex" />

    <section class="register-container">
      <div class="container">
        <!-- <img src="@/assets/images/svg/profile.svg" :alt="$t.getTranslation('LBL_PERSONAL_INFORMATION')" /> -->

        <div class="info">
          <h2>{{ $t.getTranslation("LBL_STEP_3_OF_3") }}</h2>
          <h1>{{ $t.getTranslation("LBL_PERSONAL_INFORMATION") }}</h1>

          <p>{{ $t.getTranslation("LBL_PERSONAL_INFORMATION_SUB") }}</p>
        </div>
        <f7-list no-hairlines>
          <f7-list-item divider>{{
            $t.getTranslation("LBL_ACCOUNT_INFORMATION")
          }}</f7-list-item>

          <f7-list-input
            v-model:value="formData.UserName"
            name="UserName"
            :required="validationRules?.UserName?.required"
            :minlength="validationRules?.UserName?.minimumLength"
            :maxlength="validationRules?.UserName?.maximumLength"
            :label="$t.getTranslation('LBL_USERNAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_USERNAME_INFO')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="text"
            validate
            @keydown.space.prevent
            v-on:keyup="checkUserName"
          >
          </f7-list-input>

          <f7-list-item divider v-if="!isUserNamePass">
            <p
              style="font-size: 10px; color: #ff0000; white-space: pre-line; margin: 0px"
            >
              * {{ $t.getTranslation("LBL_USERNAME_NOTE") }}
            </p>
          </f7-list-item>

          <f7-list-input
            v-model:value="formData.Email"
            name="Email"
            :required="validationRules?.Email?.required"
            :minlength="validationRules?.Email?.minimumLength"
            :maxlength="validationRules?.Email?.maximumLength"
            :label="$t.getTranslation('LBL_EMAIL')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_EMAIL_INFO')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="email"
            validate
            clear-button
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.Password"
            name="Password"
            :required="validationRules?.Password?.required"
            :minlength="validationRules?.Password?.minimumLength"
            :maxlength="validationRules?.Password?.maximumLength"
            :label="$t.getTranslation('LBL_PASSWORD')"
            :info="$t.getTranslation('LBL_PASSWORD_INFO')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            :type="isPasswordShown ? 'text' : 'password'"
            placeholder="********"
            validate
          >
            <template #content-end>
              <f7-link tabindex="-1" class="show-password" @click="togglePassword">
                <font-awesome-icon :icon="['fas', 'eye']" fixed-width />
              </f7-link>
            </template>
          </f7-list-input>

          <f7-list-item divider style="margin: 0px" v-if="!isPasswordPass">
            <p
              style="
                font-size: 10px;
                color: #ff0000;
                white-space: pre-line;
                margin: 0px;
                margin-top: 10px;
              "
            >
              * {{ $t.getTranslation("LBL_PASSWORD_NOTE") }}
            </p>
          </f7-list-item>

          <f7-list-input
            v-model:value="formData.ConfirmPassword"
            name="ConfirmPassword"
            :required="validationRules?.ConfirmPassword?.required"
            :minlength="validationRules?.ConfirmPassword?.minimumLength"
            :maxlength="validationRules?.ConfirmPassword?.maximumLength"
            :label="$t.getTranslation('LBL_CONFIRM_PASSWORD')"
            :info="$t.getTranslation('LBL_CONFIRM_PASSWORD_INFO')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            :type="isConfirmPasswordShown ? 'text' : 'password'"
            placeholder="********"
            validate
            v-on:keyup="checkPassword"
          >
            <template #content-end>
              <f7-link tabindex="-1" class="show-password" @click="toggleConfirmPassword">
                <font-awesome-icon :icon="['fas', 'eye']" fixed-width />
              </f7-link>
            </template>
          </f7-list-input>
          <!-- <f7-list-item divider>{{ $t.getTranslation("LBL_PRIMARY_INFORMATION") }}</f7-list-item>
          <f7-list-input
            v-model:value="formData.FirstName"
            name="FirstName"
            :required="validationRules?.FirstName?.required"
            :minlength="validationRules?.FirstName?.minimumLength"
            :maxlength="validationRules?.FirstName?.maximumLength"
            :label="$t.getTranslation('LBL_FIRST_NAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.LastName"
            name="LastName"
            :required="validationRules?.LastName?.required"
            :minlength="validationRules?.LastName?.minimumLength"
            :maxlength="validationRules?.LastName?.maximumLength"
            :label="$t.getTranslation('LBL_LAST_NAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.BirthDate"
            name="BirthDate"
            :min="minimumBirthDate"
            :max="maximumBirthDate"
            :minlength="validationRules?.BirthDate?.minimumLength"
            :maxlength="validationRules?.BirthDate?.maximumLength"
            :label="$t.getTranslation('LBL_BIRTH_DATE')"

            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="date"
            placeholder="YYYY/MM/DD"
            validate
          >
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.Gender"
            name="Gender"
            :required="validationRules?.Gender?.required"
            :minlength="validationRules?.Gender?.minimumLength"
            :maxlength="validationRules?.Gender?.maximumLength"
            :label="$t.getTranslation('LBL_GENDER')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <template v-if="genderList && genderList.length > 0">
              <option v-for="gender in genderList" :key="'mc_' + gender.value" :value="gender.value">{{ gender.name }}</option>
            </template>
          </f7-list-input> -->
          <!--
              this is hidden because the for you page in home is changed to weekly recommendation
              https://cocoho-ph.atlassian.net/browse/PJF-1?atlOrigin=eyJpIjoiYzU0NzhjOWM3NmFjNDcyMjlkOWNiMGU2NDAzYjE0NmIiLCJwIjoiaiJ9
          <f7-list-item divider>{{ $t.getTranslation("LBL_WINE_PREFERENCE") }}</f7-list-item>
          <f7-list-input
            v-model:value="formData.WineSweetnessId"
            name="WineSweetness"

            :label="$t.getTranslation('LBL_WINE_SWEETNESS')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"

            floating-label
            outline
            type="select"

          >
            <template v-if="wineSweetnessSelection && wineSweetnessSelection.length > 0">
              <option v-for="sweetness in wineSweetnessSelection" :key="'sweetness_' + sweetness.WineSweetnessId" :value="sweetness.WineSweetnessId">{{ sweetness.Name }}</option>
            </template>
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.WineAcidityId"
            name="WineAcidity"
            :label="$t.getTranslation('LBL_WINE_ACIDITY')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            floating-label
            outline
            type="select"
          >
            <template v-if="wineAciditySelection && wineAciditySelection.length > 0">
              <option v-for="acidity in wineAciditySelection" :key="'wa_' + acidity.Name" :value="acidity.WineAcidityId">{{ acidity.Name }}</option>
            </template>
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.WineBodyId"
            name="WineBody"

            :label="$t.getTranslation('LBL_WINE_BODY')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"

            floating-label
            outline
            type="select"


          >
            <template v-if="wineBodySelection && wineBodySelection.length > 0">
              <option v-for="body in wineBodySelection" :key="'wb_' + body.Name" :value="body.WineBodyId">{{ body.Name }}</option>
            </template>
          </f7-list-input>

          <f7-list-item divider>{{ $t.getTranslation("LBL_PRICE_RANGE") }}</f7-list-item>

          <f7-list-input
            v-model:value="formData.WinePriceId"
            name="WinePrice"
            :label="$t.getTranslation('LBL_PRICE_RANGE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            floating-label
            outline
            type="select"
          >
            <template v-if="winePriceSelection && winePriceSelection.length > 0">
              <option v-for="price in winePriceSelection" :key="'wb_' + price.WinePriceId" :value="price.WinePriceId">{{ price.Name }}</option>
            </template>
          </f7-list-input> -->

          <f7-list-input
            v-if="!hasReferralLink?.ReferralCode"
            v-model:value="formData.ReferralCode"
            name="ReferralCode"
            :required="validationRules?.ReferralCode?.required"
            :minlength="validationRules?.ReferralCode?.minimumLength"
            :maxlength="validationRules?.ReferralCode?.maximumLength"
            :label="$t.getTranslation('LBL_REFERRAL_CODE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_REFERRAL_CODE_INFO')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="text"
            validate
            clear-button
            @keydown.space.prevent
            v-on:keyup="checkReferralCode"
          >
          </f7-list-input>

          <f7-list-item
            divider
            style="margin: 0px"
            v-if="
              !isValidReferral && formData.ReferralCode && !hasReferralLink?.ReferralCode
            "
          >
            <p
              style="
                font-size: 10px;
                color: #ff0000;
                white-space: pre-line;
                margin: 0px;
                margin-top: 10px;
              "
            >
              * {{ $t.getTranslation("MSG_ERR_REFERRER_USER_NOT_FOUND") }}
            </p>
          </f7-list-item>

          <f7-list-item
            divider
            style="margin: 0px"
            v-if="isValidReferral && formData.ReferralCode"
          >
            <p
              style="
                font-size: 10px;
                white-space: pre-line;
                margin: 0px;
                margin-top: 10px;
              "
            >
              * {{ $t.getTranslation("LBL_REFERRAL_NAME") }}:
              {{ $h.renderUserName(isValidReferral) }}
            </p>
          </f7-list-item>
        </f7-list>

        <f7-button fill large raised :disabled="isButtonProcessing" @click="next">
          {{ $t.getTranslation("LBL_SUBMIT") }}
        </f7-button>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted, inject, watch } from "vue";
import dayjs from "dayjs";
import { $HTTP } from "@/utils/axios";
import { useStore } from "@/store";
import { helpers } from "@/utils/helpers.js";
import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";
import _ from "lodash";

export default defineComponent({
  name: "RegisterPrimaryPage",
  components: {},
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();

    store.dispatch("config/fetchData", { params: { genderList: 1 } });
    const genderList = computed(() => store.getters["config/getData"]?.genderList);

    const isPasswordShown = ref(false);
    const isConfirmPasswordShown = ref(false);
    const isButtonProcessing = ref(false);
    const winePreferenceList = ref({});
    const priceMin = ref(0);
    const priceMax = ref(5000);

    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const hasReferralLink = computed(() => store.getters["referral/getData"]);

    const wineSweetnessSelection = ref([]);
    const wineAciditySelection = ref([]);
    const wineBodySelection = ref([]);
    const winePriceSelection = ref([]);
    const isUserNamePass = ref(false);
    const isPasswordPass = ref(false);
    let timer = null;

    const formData = reactive({
      UserName: null,
      Email: "",
      Password: "",
      ReferralCode: "",
      ConfirmPassword: "",
      OpenId: "",
      WineBody: null,
      WineAcidityId: null,
      WineSweetnessId: null,
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    onMounted(async () => {
      let RegistrationType = "DESKTOP";

      let registerInfo = store.getters["register/getData"];
      for (let keys in formData) {
        formData[keys] = registerInfo[keys];
      }

      if (helpers.isMobile()) {
        RegistrationType = "MOBILE";
      }

      if (helpers.isInsideWeixin()) {
        RegistrationType = "WECHAT";
      }

      if (helpers.isMiniProgram()) {
        RegistrationType = "MINIPROGRAM";
      }

      formData.RegistrationType = RegistrationType;
      store.dispatch("register/setData", {
        RegistrationType: RegistrationType,
      });

      await getWinePreference();
    });

    watch(
      () => formData.BirthDate,
      (newValue, oldValue) => {
        formData.Age = dayjs().diff(newValue, "years") || 0;
      }
    );

    const minimumBirthDate = computed(() => {
      return dayjs().subtract(60, "year").format("YYYY-MM-DD");
    });

    const maximumBirthDate = computed(() => {
      return dayjs().subtract(18, "year").format("YYYY-MM-DD");
    });

    const togglePassword = () => {
      if (isPasswordShown.value) {
        hidePassword();
      } else {
        showPassword();
      }
    };

    const toggleConfirmPassword = () => {
      if (isConfirmPasswordShown.value) {
        hideConfirmPassword();
      } else {
        showConfirmPassword();
      }
    };

    const checkPassword = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (
          formData.ConfirmPassword != "" &&
          formData.Password != "" &&
          !(formData.Password == formData.ConfirmPassword)
        ) {
          isPasswordPass.value = false;
          throw new Error($t.getTranslation("ERR_PASSWORD_CONFIRM_PASSWORD_MATCH"));
        } else {
          isPasswordPass.value = true;
        }
      }, 1000);
      return;
    };

    const showPassword = (event) => {
      isPasswordShown.value = true;
    };

    const hidePassword = (event) => {
      isPasswordShown.value = false;
    };

    const showConfirmPassword = (event) => {
      isConfirmPasswordShown.value = true;
    };

    const hideConfirmPassword = (event) => {
      isConfirmPasswordShown.value = false;
    };

    const validate = (showNotifications) => {
      let validationRules = {};
      for (let keys in formData) {
        if (validationRulesConfig && validationRulesConfig[keys]) {
          validationRules[keys] = validationRulesConfig[keys];
        }
      }

      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableNextButton = computed(() => {
      let isValid = validate(false);
      return !isValid;
    });

    const getWinePreference = async () => {
      let res = await $HTTP.get(
        `/mobile/config/wine?LanguageCode=${currentLanguageCode.value}`
      );
      if (res.data.data) {
        res = res.data.data;
      }
      wineSweetnessSelection.value = res.WineSweetness;
      wineAciditySelection.value = res.WineAcidity;
      wineBodySelection.value = res.WineBody;
      winePriceSelection.value = res.WinePrice;
    };

    const checkUserName = _.debounce(async () => {
      if (!(formData.UserName == "") && formData.UserName.length >= 4) {
        try {
          let res = await $HTTP.post("/user/username/check", {
            UserName: formData.UserName,
            UserKey: null,
          });
          isUserNamePass.value = true;
        } catch (err) {
          isUserNamePass.value = false;
          helpers.catchError(err, true);
        }
      }

      if (formData.UserName.length < 4 || formData.UserName == "") {
        isUserNamePass.value = false;
      }

      return;
    }, 300);

    let isValidReferral = ref(null);
    const checkReferralCode = _.debounce(async () => {
      let res = await $HTTP.post(`/mobile/register/check/referrer`, {
        ReferralCode: formData.ReferralCode,
      });
      isValidReferral.value = res?.data?.data?.referralUser;
    }, 800);

    const next = async () => {
      try {
        let isValid = validate(true);
        let check = checkPassword();
        if (formData.Password.length < 4 || formData.Password.length > 20) {
          isPasswordPass.value = true;
          throw new Error($t.getTranslation("ERR_INVALID_PASSWORD"));
        }

        if (formData.ConfirmPassword.length < 4 || formData.ConfirmPassword.length > 20) {
          isPasswordPass.value = true;
          throw new Error($t.getTranslation("ERR_INVALID_PASSWORD"));
        }

        // const checkUserNameValid = helpers.isAplhaNumeric(formData.UserName);
        // if (!checkUserNameValid) {
        //   throw new Error($t.getTranslation("ERR_USERNAME_IS_NOT_VALID"));
        // }

        if (isValid) {
          isButtonProcessing.value = true;
          helpers.showLoader();

          let wineSelection = {
            wine: {},
          };

          wineSelection.wine["WineSweetnessId"] = formData.WineSweetnessId;
          wineSelection.wine["WineAcidityId"] = formData.WineAcidityId;
          wineSelection.wine["WineBodyId"] = formData.WineBodyId;
          wineSelection.wine["WinePriceId"] = formData.WinePriceId;

          store.dispatch("register/setData", {
            ...formData,
            ...wineSelection,
          });

          const registerInfo = store.getters["register/getData"];
          let register = `/mobile/register`;

          // REMOVE FOR PW-290
          // if (registerInfo.OpenId) {
          //   register = `/webhook/wechat/register`;
          // }

          let referral = store.getters["referral/getData"];
          registerInfo.ReferralCode = referral?.ReferralCode || formData.ReferralCode;

          let res = await $HTTP.post(register, registerInfo);
          if (res && res.status === 200 && res.data && res.data.data) {
            isButtonProcessing.value = false;
            helpers.hideLoader();

            let returnData = res.data.data;

            if (returnData && returnData.newUserVoucher) {
              store.dispatch("referral/setData", { NewUserCoupon: true });
            }

            if (returnData && returnData.referralUser) {
              store.dispatch("referral/setData", {
                ReferralUser: returnData.referralUser,
              });
            }

            store.dispatch("referral/setData", { ReferralCode: null }); //set to null after usage

            if (returnData && returnData.Token) {
              store.dispatch("register/setData", {
                WechatReturn: returnData,
                IsUserRegistered: 1,
              });
              props.f7router.navigate("/register/success/");
              return;
            }

            if (returnData && returnData.isUserRegistered) {
              store.dispatch("register/setData", {
                IsUserRegistered: 1,
              });

              props.f7router.navigate("/register/success/");
              return;
            }
          }

          throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
        }
      } catch (err) {
        isButtonProcessing.value = false;
        helpers.hideLoader();

        helpers.catchError(err, true);
      }
    };

    return {
      genderList,
      checkReferralCode,
      isValidReferral,
      hasReferralLink,
      formData,
      validationRules,
      minimumBirthDate,
      maximumBirthDate,
      isPasswordShown,
      showPassword,
      hidePassword,
      isConfirmPasswordShown,
      showConfirmPassword,
      hideConfirmPassword,
      isButtonProcessing,
      isDisableNextButton,
      next,
      currentLanguageCode,
      winePreferenceList,
      priceMin,
      priceMax,
      wineSweetnessSelection,
      wineAciditySelection,
      wineBodySelection,
      winePriceSelection,
      togglePassword,
      toggleConfirmPassword,
      checkUserName,
      checkPassword,
      isUserNamePass,
      isPasswordPass,
    };
  },
});
</script>

<style>
.input-error-message,
.item-input-error-message {
  white-space: initial !important;
  text-overflow: unset !important;
}
</style>
